<template>
  <div class="wscroll" v-if="viewFlag">
    <h2 class="content_title">{{ $t('msg.ONEX010P090.047') }}</h2> <!-- CONATINER -->
    <table class="tbl_col" style="width:200%">
      <colgroup>
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONEX010P090.048') }}</th> <!-- Status -->
          <th>{{ $t('msg.ONEX010P090.049') }}</th> <!-- Container No. -->
          <th>{{ $t('msg.ONEX010P090.050') }}</th> <!-- Seal -->
          <th>{{ $t('msg.ONEX010P090.051') }}</th> <!-- Size -->
          <th>{{ $t('msg.ONEX010P090.052') }}</th> <!-- Type -->

          <th>{{ $t('msg.ONEX010P090.053') }}</th> <!-- CGO -->
          <th>{{ $t('msg.ONEX010P090.054') }}</th> <!-- F/E -->
          <th>{{ $t('msg.ONEX010P090.055') }}</th> <!-- S'VAN -->
          <th>{{ $t('msg.ONEX010P090.056') }}</th> <!-- Plug -->
          <th>{{ $t('msg.ONEX010P090.057') }}</th> <!-- Temp -->

          <th>{{ $t('msg.ONEX010P090.058') }}</th> <!-- C/F -->
          <th>{{ $t('msg.ONEX010P090.059') }}</th> <!-- Vent -->
          <th>{{ $t('msg.ONEX010P090.060') }}</th> <!-- Weight -->
          <th>{{ $t('msg.ONEX010P090.061') }}</th> <!-- P'kg -->
          <th>{{ $t('msg.ONEX010P090.062') }}</th> <!-- P'kg Code -->

          <th>{{ $t('msg.ONEX010P090.063') }}</th> <!-- Seal(2) -->
          <th>{{ $t('msg.ONEX010P090.064') }}</th> <!-- Seal(3) -->
          <th>{{ $t('msg.ONEX010P090.065') }}</th> <!-- Seal(4) -->
          <th>{{ $t('msg.ONEX010P090.066') }}</th> <!-- Seal(5) -->

          <th>{{ $t('msg.ONEX010P090.067') }}</th> <!-- OH -->
          <th>{{ $t('msg.ONEX010P090.068') }}</th> <!-- OF -->
          <th>{{ $t('msg.ONEX010P090.069') }}</th> <!-- OB -->
          <th>{{ $t('msg.ONEX010P090.070') }}</th> <!-- OWP -->
          <th>{{ $t('msg.ONEX010P090.071') }}</th> <!-- OWS -->
          <th>{{ $t('msg.ONEX010P090.072') }}</th> <!-- Door -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.statusNm }}</td>
          <td>{{ item.cntrNo }}</td>
          <td>{{ item.sealNo1 }}</td>
          <td>{{ item.sizeNm }}</td>
          <td>{{ item.cntrTypCd }}</td>

          <td>{{ item.cgoTypCd }}</td>
          <td>{{ item.feNm }}</td>
          <td class="pr0">
            <input type="checkbox" name="svan" id="svan1" :checked="item.shprVanYn" disabled><label for="svan1"><span class="offscreen"></span></label>
          </td>
          <td class="pr0">
            <input type="checkbox" name="plug" id="plug1" :checked="item.rfSlecUseYn" disabled><label for="plug1"><span class="offscreen"></span></label>
          </td>
          <td>{{ item.rfSetupTmpr }}</td>

          <td>{{ item.cfNm }}</td>
          <td>{{ item.cntrRfStsCd }}</td>
          <td class="text_right">{{ item.wt }}</td>
          <td>{{ item.pkgQty }}</td>
          <td>{{ item.pkgCodeNm }}</td>

          <td>{{ item.sealNo2 }}</td>
          <td>{{ item.sealNo3 }}</td>
          <td>{{ item.sealNo4 }}</td>
          <td>{{ item.sealNo5 }}</td>

          <td>{{ item.ovrHght }}</td>
          <td>{{ item.ovrFwdLen }}</td>
          <td>{{ item.ovrBwrdLen }}</td>
          <td>{{ item.ovrPortLen }}</td>
          <td>{{ item.ovrStbdLen }}</td>
          <td class="pr0">
            <input type="checkbox" name="door" id="door1" :checked="item.dorOpnYn" disabled><label for="door1"><span class="offscreen"></span></label>
          </td>
        </tr>
      </tbody>
    </table>
    <br>
  </div>
</template>

<script>
import commons from '@/api/services/commons'

export default {
  name: 'ShippingLogBLDetailBLContainerPop',
  props: {
    mainData: Object
  },
  data () {
    return {
      viewFlag: '',
      items: [],
      statusNm: '',
      sizeNm: '',
      feNm: '',
      cfNm: '',
      pkgCodeNm: ''
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: function () {
    console.log('B/L Detail Container Pop')
    this.init()
  },
  watch: {
    mainData () {
      this.init()
    }
  },
  methods: {
    async init () {
      //1.공통코드 초기화
      const codeData = await commons.getCommonsCodesCommon(['01182', '01031', '01041', '01098', '01130'])
      this.statusNm = codeData['01182']
      this.sizeNm = codeData['01031']
      this.feNm = codeData['01041']
      this.cfNm = codeData['01098']
      this.pkgCodeNm = codeData['01130']

      //2.메인데이터 초기화
      const container = this.mainData.container

      if (container && container.length > 0) {
        //화면 렌더링 값 가공
        this.items = container.map((item) => {
          //STATUS 셋팅
          const statusNm = this.statusNm.filter((status) => {
              return status.cd === item.updtCatCd
          })[0]
          item.statusNm = statusNm ? statusNm.cdNm : ''

          //SIZE 셋팅
          const sizeNm = this.sizeNm.filter((size) => {
              return size.cd === item.cntrSzCd
          })[0]
          item.sizeNm = sizeNm ? sizeNm.cdNm : ''

          //F/E 셋팅
          const feNm = this.feNm.filter((fe) => {
              return fe.cd === item.feCatCd
          })[0]
          console.log('feNm:', feNm)
          item.feNm = feNm ? feNm.cdNm : ''

          //섭씨/화씨 셋팅
          const cfNm = this.cfNm.filter((cf) => {
              return cf.cd === item.rfTmprUnitCd
          })[0]
          item.cfNm = cfNm ? cfNm.cdNm : ''

          //PKG CODE 셋팅
          const pkgCodeNm = this.pkgCodeNm.filter((pkgCode) => {
              return pkgCode.cd === item.pkgCd
          })[0]
          item.pkgCodeNm = pkgCodeNm ? pkgCodeNm.cdNm : ''

          //체크박스 값들 셋팅
          item.shprVanYn = (item.shprVanYn === 'Y') ? 'checked' : ''
          item.rfSlecUseYn = (item.rfSlecUseYn === 'Y') ? 'checked' : ''
          item.dorOpnYn = (item.dorOpnYn === 'Y') ? 'checked' : ''

          return item
        })
        this.viewFlag = true
      } else {
        this.viewFlag = false
      }
    }
  }
}
</script>
