var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.viewFlag
    ? _c("div", { staticClass: "wscroll" }, [
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.047"))),
        ]),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "tbl_col", staticStyle: { width: "200%" } },
          [
            _c("colgroup"),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.048")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.049")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.050")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.051")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.052")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.053")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.054")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.055")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.056")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.057")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.058")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.059")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.060")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.061")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.062")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.063")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.064")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.065")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.066")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.067")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.068")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.069")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.070")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.071")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.072")))]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.items, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(item.statusNm))]),
                  _c("td", [_vm._v(_vm._s(item.cntrNo))]),
                  _c("td", [_vm._v(_vm._s(item.sealNo1))]),
                  _c("td", [_vm._v(_vm._s(item.sizeNm))]),
                  _c("td", [_vm._v(_vm._s(item.cntrTypCd))]),
                  _c("td", [_vm._v(_vm._s(item.cgoTypCd))]),
                  _c("td", [_vm._v(_vm._s(item.feNm))]),
                  _c("td", { staticClass: "pr0" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        name: "svan",
                        id: "svan1",
                        disabled: "",
                      },
                      domProps: { checked: item.shprVanYn },
                    }),
                    _vm._m(0, true),
                  ]),
                  _c("td", { staticClass: "pr0" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        name: "plug",
                        id: "plug1",
                        disabled: "",
                      },
                      domProps: { checked: item.rfSlecUseYn },
                    }),
                    _vm._m(1, true),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.rfSetupTmpr))]),
                  _c("td", [_vm._v(_vm._s(item.cfNm))]),
                  _c("td", [_vm._v(_vm._s(item.cntrRfStsCd))]),
                  _c("td", { staticClass: "text_right" }, [
                    _vm._v(_vm._s(item.wt)),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.pkgQty))]),
                  _c("td", [_vm._v(_vm._s(item.pkgCodeNm))]),
                  _c("td", [_vm._v(_vm._s(item.sealNo2))]),
                  _c("td", [_vm._v(_vm._s(item.sealNo3))]),
                  _c("td", [_vm._v(_vm._s(item.sealNo4))]),
                  _c("td", [_vm._v(_vm._s(item.sealNo5))]),
                  _c("td", [_vm._v(_vm._s(item.ovrHght))]),
                  _c("td", [_vm._v(_vm._s(item.ovrFwdLen))]),
                  _c("td", [_vm._v(_vm._s(item.ovrBwrdLen))]),
                  _c("td", [_vm._v(_vm._s(item.ovrPortLen))]),
                  _c("td", [_vm._v(_vm._s(item.ovrStbdLen))]),
                  _c("td", { staticClass: "pr0" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        name: "door",
                        id: "door1",
                        disabled: "",
                      },
                      domProps: { checked: item.dorOpnYn },
                    }),
                    _vm._m(2, true),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "svan1" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "plug1" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "door1" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }